import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import PageWrapper from "../components/PageWrapper";
import { Section, Title, Text, Box, List } from "../components/Core";

const TermsConditions = () => {
  return (
    <>
      <PageWrapper>
        <Section>
          <div className="pt-5"></div>
          <Container>
            <Row className="justify-content-center">
              <Col lg="9" xl="8">
                <Title variant="" className="text-center">
                  Terms & Conditions
                </Title>
                <Text variant="small">
                These terms of service constitute the entire agreement between the parties (together, the “Terms”). The use of the Niftyx website and the "Service" (together, the “Services”) is governed by these Terms. By accessing, viewing or downloading information from this website and using the Services provided by the Niftyx website, you acknowledge that you have read and considered and understand our risk disclosure statement, and that you understand and agree unconditionally to be bound by these Terms. Niftyx may, at any time, without notice, amend the Terms. You agree to continue to be bound by any such amended Terms and that Niftyx has no obligation to notify you of such amendments. You acknowledge that it is your responsibility to check these Terms periodically for changes and that your continued use of the Services offered by Niftyx
                </Text>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                  1. Restricted Jurisdictions
                  </Title>
                  <Text variant="small">
                  You are not allowed to access or use the Services if you are located, incorporated or otherwise established in, or a citizen or resident of: (i) the United States of America, the United Kingdom, the province of Québec in Canada, the Republic of Seychelles, Cuba, Crimea and Sevastopol, Iran, Syria, North Korea or Sudan; (ii) any state, country or other jurisdiction that is embargoed by the United States of America; (iii) a jurisdiction where it would be illegal according to Applicable Law for you (by reason of your nationality, domicile, citizenship, residence or otherwise) to access or use the Services; or (iv) where the publication or availability of the Services is prohibited or contrary to local law or regulation, or could subject any member of Niftyx to any local registration or licensing requirements (together, the “Restricted Jurisdictions”).
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                  2. Risks
                  </Title>
                  <Text variant="small">
                  2.1 Regulatory risk <br/> <br/>

The regulatory environment concerning cryptocurrencies continues to develop. The application and interpretation of existing laws and regulations are often largely untested and there is a lack of certainty as to how they will be applied. New laws and regulations will be promulgated in the future that apply to blockchain technology and cryptocurrencies, and related services providers, and no assurance can be given that any such changes will not adversely affect cryptocurrencies generally or the Services. It is not possible to predict how such changes would affect the price and liquidity of cryptocurrency derivatives, cryptocurrencies generally, or the Services. You understand that ultimately it is your responsibility to make sure that you comply with any and all local regulations, directives, restrictions and laws in your place(s) of residence before using our Services. We strictly state that we do not permit the use of our Services by users from a jurisdiction in which the use of our Services is not permitted (including, without limitation, Restricted Jurisdictions). We are not offering or soliciting the use of our Services to any person located in any Restricted Jurisdiction or any other jurisdiction in which the specific use of our Services is not authorised or is otherwise prohibited by local laws.
<br/><br/>
2.2. Technology risk<br/><br/>

The platform is using software in beta stage and has only undergone light audits by smart contracts engineers. Software bugs might be present that might cause the loss of all funds deposited in the smart contracts. By using the platform you acknowledge that this risk exists and that you are the sole responsible for engaging with our smart contracts in the Ethereum, Avalanche and Binance Smart Chain (BSC) blockchains. You agree that Niftyx and its operators can not be held liable for a potential loss derived from a programming bug. Our smarts contracts will be open at all times for community review on GitHub, Etherescan or other block explorers for chains where our services run.
<br/><br/>
2.3. Market risk<br/><br/>

You acknowledge that the SHROOM token does not have any purpose, utility or value other than being a yield farming experiment within the decentralised finance (DeFi) space. SHROOM tokens were generated for free without any guarantee during the process of "staking" other tokens.

If you unilateraly engage in the trading or speculation of the token you understand that nothing guarantees its value and the operators of the Service cannot be hold liable for the losses that might arise in connection to your actions.
                  </Text>
                </Box>

                <Box mt={["40px", null, "50px"]}>
                  <Title variant="card" fontSize="2rem" mb="30px">
                  3. Access Conditions
                  </Title>
                  <Text variant="small">
                  3.1: When accessing and using the Service, you must:<br/><br/>

a) not carry out any activity that: (i) involves proceeds from any illegal or unlawful activity (including activities relating to cryptocurrency tumblers, darknet markets, money laundering or terrorism financing); (ii) publishes, distributes or disseminates any illegal or unlawful material or information; or (iii) otherwise violates, or could violate, any Applicable Law;<br/><br/>

b) not attempt to undermine the security or integrity of Niftyx systems;<br/><br/>

3.2: By accessing and using the Services, you represent, warrant and undertake that:<br/><br/>

a) you have accepted the Terms;<br/><br/>

b) you are at least 18 years of age and have the capacity to accept the Terms;<br/><br/>

c) you are the legal owner (or an appropriately authorised representative of the legal owner) of the funds you add to the platform and that the same funds derive from a legitimate source;<br/><br/>

d) your use of the Services does not constitute a breach of Applicable Law;<br/><br/>

e) you are aware of the risks in using the Services provided by Niftyx and have the necessary experience and knowledge to understand the risks involved in relation to each Service provided by Niftyx. These risks include the high volatility risk of cryptocurrencies, and that you may lose all of the funds if the market moves against you;<br/><br/>

f) you acknowledge and agree that the Services should only be used by you if you:<br/><br/>

i) have knowledge and experience interacting with smart contracts on the ethereum and/or EVM-compatible ecosystems;<br/><br/>

ii) have knowledge and experience in highly volatile markets;<br/><br/>

iii) are operating or farming with funds you can afford to lose; and<br/><br/>

iv) have a high risk tolerance;<br/><br/>

g) you will not be involved or initiate any form of market manipulation;<br/><br/>

h) you are not allowed to access or use the Services if you are located in, incorporated or otherwise established in, or a citizen or resident of any Restricted Jurisdiction, and that Niftyx reserves the right to block your access to the website immediately if you are accessing the Services from any Restricted Jurisdiction or have given false representations as to your location, place of incorporation or establishment, citizenship or place of residence;<br/><br/>

k) you have not; (i) violated; (ii) been fined, debarred, sanctioned, the subject of economic sanctions-related restrictions, or otherwise penalised under; (iii) received any oral or written notice from any government concerning actual or possible violation by you under; or (iv) received any other report that you are the subject or target of sanctions, restrictions, penalties, or enforcement action or investigation under, any Applicable Law (including but not limited to anti-money laundering laws, counterterrorism financing laws, anti-corruption laws or economic sanctions laws); and<br/><br/>

l) neither you nor any of your affiliates is: (i) itself, or owned or controlled by, a sanctioned person; (ii) involved in any transaction, transfer, or conduct, whether or not by using or receiving the Service, that is likely to result in you or your affiliates becoming a sanctioned person; or (iii) located in, incorporated or otherwise established in, or a citizen or resident of, a Restricted Jurisdiction.
                  </Text>
                </Box>
              </Col>
            </Row>
          </Container>
        </Section>
      </PageWrapper>
    </>
  );
};
export default TermsConditions;
